.purr-blocker-card {
  @include flexbox(column, center, flex-start);
  padding-top: 25vh;
  background-color: transparentize($newsGray100, 0.4);
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2001;
}

.purr-blocker-card__content {
  background-color: $white;
  padding: 40px 34px;
  border-radius: 3px;
  box-shadow:
    0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.purr-blocker-card__text {
  @include flexbox(column);
  color: $newsGray100;
  max-width: 268px;
}

.purr-blocker-card__heading {
  font: 600 rem(20) / rem(34) $franklin;
  margin-bottom: 16px;
}

.purr-blocker-card__terms {
  font: 500 rem(16) / rem(24) $franklin;
  margin-bottom: 24px;

  a {
    color: $newsLightContentAccent;
  }
}

.purr-blocker-card__button {
  font: 600 rem(16) / rem(24) $franklin;
  background-color: $newsGray100;
  cursor: pointer;
  width: 100%;
  color: $newsDarkContentPrimary;
  padding: 10px;
  border: 0;
}

@media (min-width: 600px) {
  .purr-blocker-card__content {
    padding: 56px 60px;
  }

  .purr-blocker-card__text {
    max-width: 480px;
  }

  .purr-blocker-card__heading {
    font-size: rem(26);
    margin-bottom: 24px;
  }

  .purr-blocker-card__terms {
    font: 500 rem(18) / rem(26) $franklin;
    margin-bottom: 32px;

    a {
      text-decoration: underline;
    }
  }
}
