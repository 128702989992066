@import 'shared/scss-helpers/index.scss';

// ----------
// CONTAINERS
// ----------
.pz-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $gray7;
  font-size: 13px;
  min-height: 412px;
  padding: 20px 0px 42px;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.pz-footer__wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;

  min-height: 412px;
  max-width: 1328px;
  padding: 42px 32px 0;
}

// --------
// SECTIONS
// --------
.pz-footer__section {
  padding: 0 24px;
}

.pz-footer__section-header {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 15px;

  &:not(:first-child) {
    margin-top: 31px;
  }
}

.pz-footer__list {
  list-style-type: none;
}

.pz-footer__link,
.pz-footer__legal-link {
  margin: 12px 0;

  a,
  span {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
}

.pz-footer__legal-link.default-hidden {
  display: none;
}

// ----------
// ABOUT US
// ----------
.pz-footer__about-us {
  flex-grow: 2;
  flex-basis: 300px;
}

.pz-footer__featured-text {
  font-family: $cheltenham;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;

  a {
    color: $blue2;
    display: inline;
  }
}

// ----------
// RESPONSIVE
// ----------
@include view-width-max(lg) {
  // CONTAINERS
  .pz-footer__wrapper {
    padding-bottom: 48px;
  }

  // ABOUT US
  .pz-footer__about-us {
    flex-basis: 100%;
    padding-bottom: 32px;
  }

  .pz-footer__featured-text {
    font-size: 18px;
    line-height: 25px;
  }

  // SECTIONS
  .pz-footer__section-link-col {
    width: 25%;
  }

  .pz-footer__section-header {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.7px;
  }

  .pz-footer__link,
  .pz-footer__legal-link {
    a,
    span {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.5px;
    }
  }
}

@include view-width-min(xl) {
  .pz-footer__legal {
    width: 100%;
  }
  .pz-footer__legal-links {
    display: flex;
    justify-content: flex-start;
  }
  .pz-footer__legal-link {
    display: inline-flex;

    a,
    span {
      font-size: 11px;
      line-height: 16px;
    }
    padding: 0;
    margin: 0;
    letter-spacing: 0.5px;

    & + ::before {
      color: $gray1;
      content: '|';
      padding: 0 5px;
    }
  }
}

@include view-width-max(md) {
  // SECTIONS
  .pz-footer__section-link-col {
    width: 50%;
  }
}

@include view-width-max(xs) {
  .pz-footer__wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
