@import 'shared/scss-helpers/index.scss';

.pz-offline-ticker {
  background-color: $gray2;
  color: white;
  font-size: 0.875em;
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
  transition: all 0.5s ease-in-out;

  &.is-offline {
    height: auto;
    opacity: 1;
    padding: 12px 0;
  }

  .offline-ticker-dismiss {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
}
