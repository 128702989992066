@import 'shared/scss-helpers/index.scss';

.pz-error {
  @include flexbox(column);
  width: 100%;
  padding-bottom: 20px;
  margin: 4% 0;
  font: -apple-system-body;
  font-family: nyt-franklin;
}

.pz-error__message {
  text-align: center;
  padding: 30px;

  h1 {
    font-style: normal;
    @include font-size-clamp(2em, 90px);
    line-height: 1.15;
    font-weight: 700;
    margin-bottom: 25px;
  }

  p {
    font-size: 1.125em;
    line-height: 1.39;
    margin-bottom: 30px;
    max-width: 510px;
  }
}

.pz-error__icon {
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-bottom: 30px;
  max-width: 300px;

  &.bee-a-subscriber {
    background-image: url('/public/assets/bee-a-subscriber.svg');
    width: 198px;
    height: 88px;
  }

  &.bee-mused {
    background-image: url('/public/assets/bee-mused-0.svg');
    height: 140px;
    width: 140px;
  }

  &.page-not-found {
    background-image: url('/public/assets/page-not-found.svg');
    height: 140px;
    width: 140px;
  }
}

.pz-error__stack-trace {
  margin: 20px;
  padding: 20px;
  border: 1px solid $gray2;

  pre {
    white-space: normal;
  }
}

.pz-error__link {
  color: black;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

// This button is very similar to the one in spelling-bee/scss/modals.scss
.pz-error__button {
  display: inline-block;
  color: white;
  background-color: black;
  border: 1px solid $gray4;
  cursor: pointer;
  padding: 15px 25px;
  transition: background-color 150ms;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 1.5em;
  font-size: 1em;

  // &:hover {
  //   background-color: darken(white, 5);
  // }
  // &:active {
  //   background-color: darken(white, 10);
  // }
}

.pz-error-img {
  background-repeat: no-repeat;
  background-position: center;

  @include view-width-min(xs) {
    background-image: url('/public/assets/error500-illustration-s.svg');
    height: 300px;
    width: 300px;
    margin-top: 50px;
  }

  @include view-width-min(sm) {
    background-image: url('/public/assets/error500-illustration-s.svg');
    height: 450px;
    width: 450px;
    margin-top: 50px;
  }

  @include view-width-min(md) {
    background-image: url('/public/assets/error500-illustration-m.svg');
    height: 500px;
    width: 700px;
    margin: -80px;
  }

  @include view-width-min(lg) {
    background-image: url('/public/assets/error500-illustration-xl.svg');
    height: 250px;
    width: 900px;
    margin-top: 50px;
  }
}

.pz-error-img-1 {
  background-repeat: no-repeat;
  background-position: center;

  @include view-width-min(xs) {
    background-image: url('/public/assets/error404-illustration-s.svg');
    height: 300px;
    width: 300px;
    margin-top: 100px;
  }

  @include view-width-min(sm) {
    background-image: url('/public/assets/error404-illustration-s.svg');
    height: 400px;
    width: 400px;
  }

  @include view-width-min(md) {
    background-image: url('/public/assets/error404-illustration-m.svg');
    height: 500px;
    width: 700px;
    margin: -80px;
  }

  @include view-width-min(lg) {
    background-image: url('/public/assets/error404-illustration-xl.svg');
    height: 250px;
    width: 900px;
    margin-top: 200px;
  }
}
