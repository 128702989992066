.pz-icon {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  min-height: 20px;
  min-width: 20px;
  margin-right: 8px;
}

.pz-icon-right {
  margin-right: unset;
  margin-left: 8px;
}

.pz-icon-pips {
  background-image: url('/public/assets/icons/pips.svg');
}

.pz-icon-daily {
  background-image: url('/public/assets/icons/daily.svg');
}

.pz-icon-mini {
  background-image: url('/public/assets/icons/mini.svg');
}

.pz-icon-letter-boxed {
  background-image: url('/public/assets/icons/letter-boxed.svg');
}

.pz-icon-spelling-bee {
  background-image: url('/public/assets/icons/spelling-bee.svg');
}

.pz-icon-spelling-bee-wings {
  background-image: url('/public/assets/spelling-bee.svg');
}

.pz-icon-wordle {
  background-image: url('/public/assets/icons/wordle.svg');
}

.pz-icon-connections {
  background-image: url('/public/assets/icons/connections.svg');
}

.pz-icon-strands {
  background-image: url('/public/assets/icons/strands.svg');
}

.pz-icon-tiles {
  background-image: url('/public/assets/icons/tiles.svg');
}

.pz-icon-sudoku {
  background-image: url('/public/assets/expansion-games/sudoku-card-icon.svg');
}

.pz-icon-nyt {
  background-image: url('https://www.nytimes.com/games-assets/v2/assets/wordle/nav-icons/NYT-Icon-Normalized.svg');
}

.pz-icon-cooking {
  background-image: url('https://www.nytimes.com/games-assets/v2/assets/wordle/nav-icons/Cooking-Icon-Normalized.svg');
}

.pz-icon-wirecutter {
  background-image: url('https://www.nytimes.com/games-assets/v2/assets/wordle/nav-icons/Wirecutter-Icon-Normalized.svg');
}

.pz-icon-athletic {
  background-image: url('https://www.nytimes.com/games-assets/v2/assets/wordle/nav-icons/Athletic-Icon-Normalized.svg');
}

.pz-icon-sports-connections {
  background-image: url('https://www.nytimes.com/games-assets/v2/assets/wordle/nav-icons/NYT-Connections-Sports-Icon.svg');
}

.pz-icon-print {
  background-image: url('/public/assets/print.svg');
}

.pz-icon-print-black {
  background-image: url('/public/assets/print-black.svg');
}

.pz-icon-pause {
  background-image: url('/public/assets/timer-pause.svg');
}

.pz-icon-close {
  background-image: url('/public/assets/icon-close.svg');

  [data-mode='dark'].display-settings-enabled & {
    background-image: url('/public/assets/icon-close-dark.svg');
  }
}

.pz-icon-rotate {
  background-image: url('/public/assets/icon-rotate.svg');
}

.pz-icon-caret-right {
  background-image: url('/public/assets/icon-caret-right.svg');
}

.pz-icon-banner-caret-right {
  background-image: url('/public/assets/banner-right.svg');
}

.pz-icon-arrow-up {
  background-image: url('/public/assets/icons/arrow-up.svg');
}

.pz-icon-arrow-down {
  background-image: url('/public/assets/icons/arrow-down.svg');
}

.pz-icon-back {
  background-image: url('/public/assets/back.svg');
}

.pz-icon-crossword-app {
  background-image: url('/public/assets/icons/crossword-app-color.svg');
}

.pz-icon-sparkle {
  background-image: url('/public/assets/icons/sparkle.svg');
}

.pz-icon-share-outline {
  background-image: url('/public/assets/share-outline.svg');
}

.pz-icon-qr-code {
  background-image: url('/public/assets/apps/appQR_2.png');
}

.pz-icon-inline-games {
  background-image: url('/public/assets/icons/inline-games-all.svg');
}

.pz-icon-connections-group {
  background-image: url('/public/assets/icons/group-three.svg');
}

.pz-icon-connections-archive {
  background-image: url('/public/assets/icons/connections-archive.svg');
}
