@import 'shared/scss-helpers';

/* BUTTONS */
.pz-moment__button-group {
  margin-bottom: 22px;
}

.pz-moment__button-group > .header {
  line-height: 20px;
  font-family: $franklin;
  font-size: 16px;
  margin-bottom: 9px;
  font-weight: $weight-medium-bold;
}

.pz-moment__button-wrapper.vertical {
  @include view-width-min(md) {
    flex-direction: column;
    align-items: center;
  }
}

.pz-moment__button {
  position: relative;
  border: none;
  height: 3em;
  border-radius: 1.5em;
  align-content: center;
  font-size: clamp(0.95em, 1vw + 0.125em, 1em);
  font-weight: $weight-medium-bold;
  font-family: 'nyt-franklin';
  letter-spacing: 0.05em;
  margin: 0 10px 8px;
  background: var(--bg-btn-emphasis-constant);
  color: var(--text-alternate-constant);
  padding: 1px 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  min-width: 9.375em; // fallback if min not supported
  min-width: calc(min(9.375em, 300px));

  @include view-width-min(md) {
    min-width: 9.375em;
  }

  &:last-of-type {
    margin-bottom: 24px;

    @include view-width-min(md) {
      margin-bottom: 28px;
    }

    @include view-width-min(lg) {
      margin-bottom: 32px;
    }
  }

  &.secondary {
    background: var(--transparent);
    color: var(--text-constant);
    border: 1px solid var(--stroke-constant);
    letter-spacing: 0.01em;
  }

  &.wide {
    min-width: 11.25em;
    min-width: calc(min(11.25em, 360px));
  }

  &.extra-wide {
    min-width: 12.25em;
    min-width: calc(min(12.25em, 360px));
    letter-spacing: 0.025em;
  }

  &.transparent {
    opacity: 50%;
  }

  &.extra-extra-wide {
    min-width: 16.0625em;
    min-width: calc(min(16.0625em, 514px));
    letter-spacing: 0.025em;
  }

  &.link {
    font: $weight-bold 1em/0.8 nyt-franklin;
    height: auto;
    color: $black;
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $black;
    padding: 0;
    margin: 30px auto;
    letter-spacing: 0;
  }

  @include tiny-phone {
    &.extra-extra-wide {
      min-width: 100%;
    }
  }

  &.above-link {
    margin-bottom: 8px;
  }
}

// to vertically center text on anchor buttons
a.pz-moment__button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pz-moment__button:focus {
  outline: none;
}

.pz-moment__button:focus::after {
  content: '';
  display: block;
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 2px dotted lightblue;
  border-radius: 1.875em;
}

.pz-moment__button:hover {
  cursor: pointer;
}

.pz-moment__button.primary:active {
  background: $black;
}

.pz-moment__button.secondary:active {
  color: $black;
  border-color: $black;
}

.pz-moment__button.extra-extra-wide:active {
  color: var(--text-alternate);
  background: var(--bg-btn-emphasis);
  opacity: 0.8;
}

.pz-moment__actions .pz-moment__button + .pz-moment__button {
  margin-top: 12px;

  @include view-width-min(md) {
    margin-top: 20px;
  }
}

.pz-moment__button--padlock {
  background: url('/public/assets/padlock-black.svg') center/100%
    no-repeat;
  width: 0.5625em;
  height: 0.75em;
  display: inline-block;
  margin-right: 8px;
}

.pz-moment__button--checked {
  background: url('/public/assets/icons/check-white.svg') center/100%
    no-repeat;
  width: 0.5625em;
  height: 0.75em;
  display: inline-block;
  margin-right: 8px;
}

.pz-moment__button.primary {
  .pz-moment__button--padlock {
    background-image: url('/public/assets/padlock.svg');
  }
}

.cta-full-width {
  @include view-width-max(sm) {
    width: 100%;
  }
}
